@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');


:root{
  --header-background-color: rgb(25, 25, 25); /* Darker header */
  --header-background-fade-to: rgb(44, 44, 44); /* Gradient end for header */
   --shadow-color: rgba(255, 255, 255, 0.1); 
   --hover-shadow: rgba(255, 255, 255, 0.2);  
  --continue-area-bg: rgb(59, 58, 58); /* Darker continue area */
  --primary-text-color: #f1f1f2; /* White primary text */
  --secondary-text-color: #b1b1b1; /* Light gray secondary text */
  --search-input-text-color: #e6e6e6; /* Lighter text for search input */
  --character-primary-text: #ffffff; /* White text for characters */
  --character-bg: #333333; /* Darker character background */
  --character-bg-hover: #3d3d3d; /* Hover state for character background */
  --selected-border: #ffffff; /* White border for selected items */
  --action-button-color: #4f4f4f; /* Darker action button */
  --action-button-hover: #626262; /* Hover state for action button */
  --light-accent-color: #676767; /* Lighter accent for dark mode */
  --font_a: 'Transducer Hairline', sans-serif;
  --font_b: 'Transducer Regular', sans-serif;
  --tag-bg-color: #171515; /* Darker tag background */
  --tag-selected-color: rgb(240, 217, 217); /* Selected tag color */
  --tag-border-color: #ffffff; /* White border for tags */
  --font_b_extended: 'Transducer Extended', sans-serif; /* Font family */
  --welcome-bg-primary: #3a3a3a; /* Welcome background primary */
  --welcome-bg-secondary: #4d4d4d; /* Welcome background secondary */
  --create-background-color: rgb(35, 35, 35); /* Trending character card background */
  --create-background-fade-to: rgb(39, 38, 38); /* Gradient end for create background */
  --input-area-color: rgb(11, 11, 11); /* Input area color */
  --secondary-input-area-color: rgb(70, 70, 70); /* Secondary input area color */
  --alt-text-color: rgb(180, 180, 180); /* Alternative text color */
  --content-text-color: rgb(255, 255, 255); /* Content text color */
  --border-color:  #817f7f;
  --chat-container-text-color: rgb(230, 230, 230); /* Chat container text */
  --character-card-bg: #222222; /* Character card background */
  --button-text-color: #ffffff; /* Button text color */
  --button-color: #4f4f4f; /* Button color */
  --trending-character-card-bg: rgb(51, rgba(27, 9, 1, 0.6); 50, 50); /* Create background */
  --chat-container-color: #404040; /* Chat container color */
  --trending-character-card-text: var(--primary-text-color); /* Trending character card text */
  --continue-character-card-bg: #3a3a3a; /* Continue character card background */
  --trending-character-card-hover: #4d4d4d; /* Trending character card hover */
  --profile-card-bg: rgba(27, 9, 1, 0.6);
  --addnl-button-color: #691e6d;
  --cast-container-bg: #1c1c1c; /* Cast container background */
  --cast-container-text: #e8f4fa; /* Cast container text */
  --darker-bg: #2a2a2a; /* Darker background color */
  --topic-section-bg: #5a5a5a; /* Topic section background */
  --topic-section-bg-fade-to: #6b6b6b; /* Gradient end for topic section */
  --addnl-button-color: #691e6d; /* Additional button color */
  --selected-border: #ad1460; /* Selected border color */
  --content-bg-color: rgba(28, 28, 28); /* Content background color */
  --content-bg-fade-to: rgb(40, 40, 40, 0.8); /* Gradient end for content background */
  --character-primary-text: #ffffff; /* White character primary text */
  --selected-border: #1e0b56; /* Selected border color */
  --light-accent-color: #9796ac; /* Light accent color */
  --header-height: 12vh; /* Header height */
  --logout-button-color: var(--header-background-color); /* Logout button color */
  --profile-bg-color: #3a3a3a; /* Profile background color */
  --tab-button-unselected: var(--create-background-color); /* Tab button unselected color */
  --shadow-color: rgba(255, 255, 255, 0.1); /* Shadow color */
  --hover-shadow: rgba(255, 255, 255, 0.2); /* Hover shadow color */
  --tab-content-bg-color: rgb(50, 50, 50); /* Tab content background color */
  --unselected-text-color: whitesmoke; /* Unselected text color */
  --topic-section-bg: #5a5a5a; /* Topic section background */
  --header-height: 6.7rem; /* Header height */
  --character-card-text: rgb(211, 229, 245); /* Character card text */
  --character-card-bg: #4f4f53; /* Character card background */
  --button-text-color: #ffffff; /* Button text color */
  --button-color: #a0a5bb; /* Button color */
  --addnl-button-color: #691e6d; /* Additional button color */
  --bottom-navbar-bg : var(--header-background-color); /* Bottom navbar background */
  --orange-brand-accent: #FF4500;
  --blue-brand-accent: #1D1B47;
  --font_chat: 'DM Sans', sans-serif;
  --cast-window-bg: 
  linear-gradient(rgb(32, 32, 36), transparent),
  linear-gradient(to top left, rgb(60, 64, 67), transparent),
  linear-gradient(to top right, rgb(45, 48, 50), transparent);

}

.dark{
  --header-background-color: rgb(25, 25, 25); /* Darker header */
  --header-background-fade-to: rgb(44, 44, 44); /* Gradient end for header */
   --shadow-color: rgba(255, 255, 255, 0.1); 
   --hover-shadow: rgba(255, 255, 255, 0.2);  
  --continue-area-bg: rgb(59, 58, 58); /* Darker continue area */
  --primary-text-color: #f1f1f2; /* White primary text */
  --secondary-text-color: #b1b1b1; /* Light gray secondary text */
  --search-input-text-color: #e6e6e6; /* Lighter text for search input */
  --character-primary-text: #ffffff; /* White text for characters */
  --character-bg: #333333; /* Darker character background */
  --character-bg-hover: #3d3d3d; /* Hover state for character background */
  --selected-border: #ffffff; /* White border for selected items */
  --action-button-color: #4f4f4f; /* Darker action button */
  --action-button-hover: #626262; /* Hover state for action button */
  --light-accent-color: #676767; /* Lighter accent for dark mode */
  --font_pixelify: 'Pixelify Sans', sans-serif; /* Font family */
  --font_b: 'Transducer Regular', sans-serif; /* Font family */
  --tag-bg-color: #171515; /* Darker tag background */
  --tag-selected-color: rgb(240, 217, 217); /* Selected tag color */
  --tag-border-color: #ffffff; /* White border for tags */
  --font_b_extended: 'Transducer Extended', sans-serif; /* Font family */
  --welcome-bg-primary: #3a3a3a; /* Welcome background primary */
  --welcome-bg-secondary: #4d4d4d; /* Welcome background secondary */
  --create-background-color: rgb(35, 35, 35); /* Trending character card background */
  --create-background-fade-to: rgb(39, 38, 38); /* Gradient end for create background */
  --secondary-input-area-color: rgb(70, 70, 70); /* Secondary input area color */
  --alt-text-color: rgb(180, 180, 180); /* Alternative text color */
  --content-text-color: rgb(255, 255, 255); /* Content text color */
  --border-color:  #817f7f;
  --chat-container-text-color: rgb(230, 230, 230); /* Chat container text */
  --character-card-bg: #222222; /* Character card background */
  --button-text-color: #ffffff; /* Button text color */
  --button-color: #4f4f4f; /* Button color */
  --trending-character-card-bg: rgb(51, 50, 50); /* Create background */
  --chat-container-color: #404040; /* Chat container color */
  --trending-character-card-text: var(--primary-text-color); /* Trending character card text */
  --continue-character-card-bg: #3a3a3a; /* Continue character card background */
  --trending-character-card-hover: #4d4d4d; /* Trending character card hover */
  --profile-card-bg: #1c1c1d; /* Profile card background */
  --addnl-button-color: #691e6d;
  --cast-container-bg: #1c1c1c; /* Cast container background */
  --cast-container-text: #e8f4fa; /* Cast container text */
  --darker-bg: #2a2a2a; /* Darker background color */
  --topic-section-bg: #5a5a5a; /* Topic section background */
  --topic-section-bg-fade-to: #6b6b6b; /* Gradient end for topic section */
  --addnl-button-color: #691e6d; /* Additional button color */
  --selected-border: #ad1460; /* Selected border color */
  --content-bg-color: rgba(28, 28, 28); /* Content background color */
  --content-bg-fade-to: rgb(40, 40, 40, 0.8); /* Gradient end for content background */
  --character-primary-text: #ffffff; /* White character primary text */
  --selected-border: #1e0b56; /* Selected border color */
  --light-accent-color: #9796ac; /* Light accent color */
  --header-height: 12vh; /* Header height */
  --logout-button-color: var(--header-background-color); /* Logout button color */
  --profile-bg-color: #3a3a3a; /* Profile background color */
  --tab-button-unselected: var(--create-background-color); /* Tab button unselected color */
  --shadow-color: rgba(255, 255, 255, 0.1); /* Shadow color */
  --hover-shadow: rgba(255, 255, 255, 0.2); /* Hover shadow color */
  --tab-content-bg-color: rgb(50, 50, 50); /* Tab content background color */
  --unselected-text-color: whitesmoke; /* Unselected text color */
  --topic-section-bg: #5a5a5a; /* Topic section background */
  --header-height: 6.7rem; /* Header height */
  --character-card-text: rgb(211, 229, 245); /* Character card text */
  --character-card-bg: #4f4f53; /* Character card background */
  --button-text-color: #ffffff; /* Button text color */
  --button-color: #a0a5bb; /* Button color */
  --addnl-button-color: #691e6d; /* Additional button color */
  --bottom-navbar-bg : var(--header-background-color); /* Bottom navbar background */
  --orange-brand-accent: #FF4500;
  --blue-brand-accent: #1D1B47;
  --cast-window-bg: 
  linear-gradient(rgb(32, 32, 36), transparent),
  linear-gradient(to top left, rgb(60, 64, 67), transparent),
  linear-gradient(to top right, rgb(45, 48, 50), transparent);

}


/* Header styles * /
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* .app-header h1:hover{
  color: var(--secondary-text-color);
}
.app-header {
  color: white;
  text-align: center;
  font-family: var(--font_b_extended);
  
  position: relative;
  transition: background 0.3s ease;
  display: flex;
justify-content: space-between;
  height: 4rem;
}

.app-header h1{
  color: var(--primary-text-color);
  font-family: var(--font_b_extended);
  padding: 0rem  1rem;
  margin-right: auto;
} */
.dark-mode-switch{
  margin-top: 0.8rem;
  color: var(--primary-text-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.logo{
  padding: 0.5rem;
  height:5rem;
  width: 5rem;
}


/* Subtle animation on header text */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-right {
  display: flex;
  align-items: center;
  margin-left: auto; 
}

.profile-icon{

  color: var(--primary-text-color);
  margin: auto 1rem;
}
.profile-icon:hover{

  color:var(--secondary-text-color);
}


.nav-desktop {
  display: flex;
  gap: 2rem;
  margin: auto 2%;
}

.nav-link {
  text-decoration: none;
  color: var(--primary-text-color);
  padding: 0rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background 0.3s;
}

.nav-link:hover {
  /* background: rgba(0, 0, 0, 0.1); */
  color: var(--secondary-text-color)
}

.menu-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  display: none;
}

.nav-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: var(--header-background-fade-to);
  box-shadow: 0px 2px 10px var(--shadow-color);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  z-index: 4;
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-mobile{
    display: flex;
  }
  .nav-mobile a:last-child {
    border-bottom: none;
  }
  .nav-link-hamburger {
    color: var(--primary-text-color);
    border-bottom: 1px solid var(--light-accent-color);
  }
  .nav-link{
    font-size: xx-small;
  }
  .menu-btn {
    display: block;
    color: var(--action-button-color);
    font-size: 2rem;
    padding: 1rem;
    
  }
  .menu-btn:hover{
    color: var(--secondary-text-color);
  }
}

.custom-swiper-button-prev, .custom-swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-text-color);
 background-color: var(--orange-brand-accent);
 opacity: 0.8;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px; 
  z-index: 1000; 
}
.load-next, .load-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(137, 143, 186);
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 15px; /* Adjust the size of the arrow symbol */
  z-index: 10; /* Ensure it's above other elements */
  transition: transform 0.3s ease;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  font-family: var(--font_b);
}

.load-next {
  right: 4px;
}

.load-prev {
  left: 4px;
}

.load-next:hover, .load-prev:hover {
  transform: translateY(-50%) scale(1.05); /* Combine scaling with the existing translateY transformation */
  background-color: #7f72a4;
}
.custom-swiper-button-prev {
  left: 10px;
}

.custom-swiper-button-next {
  right: 10px;
}

.custom-swiper-button-prev:hover, .custom-swiper-button-next:hover {
 filter: brightness(65%); /* Dim the button on hover */
}




.homepage-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  
}

@media screen and (max-width: 768px) {
  .homepage-container {
    margin-left: 0;
    padding-left: 0;
  }
  
}

.generic-button{
  background-color: var(--orange-brand-accent) !important;
  color: var(--primary-text-color) !important;
  border: none !important;
  border-radius: 0.7rem !important;
  padding: 0.5rem 1rem !important;
  min-width: 6rem !important;
  max-width: 10rem !important;
  font-family: var(--font_b) !important;
  cursor: pointer;
  transition: background 0.3s !important;

}

.generic-button:hover{
  filter: brightness(90%); /* Decrease brightness by 10% on hover */
}

/* NEW SECTION FOR V1*/
.continue-section {
  display: flex;
  /* flex-direction: column; */
  color: var(--primary-text-color);
  padding-top: 0.5rem;
  padding-left: 0.8rem;
  font-family: var(--font_b);
}
.landing-tab-container{
  margin:1rem 2rem 0 2rem;
  justify-content: center;
  /* border-bottom: 3px solid var(--primary-text-color); */
  width: 100%;
}
.custom-tab-button{
  font-family: var(--font_b) !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
}
.custom-tab-button.inactive{
  background-color: transparent !important;
  color: var(--primary-text-color) !important;
}
.custom-tab-button.active{
  color: var(--primary-text-color) !important;
  background-color: var(--orange-brand-accent) !important;
}

@media screen and (max-width:768px) {
  .landing-tab-container{
    margin: 1rem 4rem;
  }
  .custom-tab-button{
    font-size: 0.8rem !important;
  }
  
}
.featured-list-header{
  display: flex;
  color: var(--primary-text-color);
  padding-left: 0.8rem;
  font-family: var(--font_b);
}
.featured-list-header h2{
  text-shadow: 2px 2px 4px #1D1B47
}
.main-section {
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font_b_extended);
}


@media screen and (max-width:768px) {
  .continue-section h2 {
    font-size: 1.2rem;
    margin: 0;
    margin-top: 1rem;
  }

}
.continue-section h2 {
  font-size: 1.5rem;
  margin-top:0.7;
  margin-bottom: 0.1;
}
.trending-characters{
  padding:1rem;
  /* padding-left:.4rem; */
}
.arrow{
  background-color: black;
}

.mySwiper .swiper-slide {
  width: 10.7em;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .mySwiper .swiper-slide {
    width: 8.7rem;
  }
}

.mySwiper {
  /* Add some more styles to ensure the swiper container allows for partially visible slides */
  
  overflow: visible;
}

.announcement {
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

.search-bar {
  
  display: flex;
  width: 22rem;
  padding: 0.2rem;
  margin-bottom: 0.1rem;;

}
.search-icon{
  margin: auto 0.4rem;
  color: var(--primary-text-color);
  font-size: 5rem;

}
.search-input{
  color: var(--primary-text-color) !important;
  font-family: var(--font_b);
  font-size: 17px;

}

/* Footer styling */
.footer {
  margin-top: auto; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0),var(--create-bg-fade-to));
  z-index: 2004;
}
@media screen and (max-width: 768px) {
  .footer {
  padding-top: 60px;
   padding-bottom: 60px;
  }  
}
.footer-content {
  display: flex;
  align-items: center;
  font-family: var(--font_b_extended);
}
.footer-content p{
  color: var(--primary-text-color);
  cursor: pointer;
  font-family: var(--font_b);
  font-size: 0.9rem;
  margin: 10px;
  transition: color 0.3s ease;
}
.footer-content p:hover{
  color: var(--orange-brand-accent);
}
@media screen and (max-width: 768px) {
  .footer-content p {
    font-size: 0.6rem;
  }
  
}
.social-icon:hover{
  transform: scale(1.1);
  transition: transform 0.1s ease-in-out;
}

.footer-left {
  font-family: var(--font_b);
  font-weight: 600;
  font-size: 20px;
  color: var(--primary-text-color);
}



@media screen and (max-width:768px) {
  /* .footer-content {
    flex-direction: column;
  } */

  .footer-left {
    font-size: 0.7rem;
  }
  
}

.loading-centered{
  display: flex;
  /* background-color: var(--create-background-color); */
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.twitter-icon{
  color: var(--primary-text-color);
  font-size: 2rem;
  height: 2rem !important;
  width: 2rem !important;
  margin-bottom: 4%;
}


.faq-container {
  max-width: 90%;
  margin: auto;
  min-height: 100vh;
  padding: 3rem;
  color: var(--primary-text-color);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  font-family: Arial, sans-serif;
}
.faq-container h1{
  font-family: var(--font_b);
  font-size: 3rem;
  /* padding: 1rem; */
  color: var(--primary-text-color);
}

.faq-title{
  font-family: var(--font_b);
  color: var(--primary-text-color);
}

.community-rules{
  max-width: 90%;
  margin: auto;
  min-height: 100vh;
  padding: 3rem;
  color: var(--primary-text-color);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  /* font-family: var(--font_b); */
}

.community-rules h1{
  font-family: var(--font_b);
  font-size: 3rem;
  color: var(--primary-text-color);
}


.community-rules p br {
  margin-bottom: 10px; /* Add some space between the lines if needed */
}

.tag-button{
  color: var(--tag-border-color);
  /* border: var(--blue-brand-accent) 2px solid; */
  box-shadow: 0 2px 5px rgba(218, 216, 216, 0.1);
  border: none;
  border-radius: 1rem;
  font-family: var(--font_b);
  padding: 0.6rem;
  font-size: 1rem;
  cursor: pointer;
  max-width: 11.2rem;
  margin: 0.5rem;
}

.tag-button:hover {
  filter: brightness(90%);
  background-color: darken(var(--secondary-text-color), 10%); /* Darken the button color slightly */
  
}
@media screen and (max-width:768px) {
  .tag-button {
    font-size: 0.9rem;
    padding: 0.3rem 0.5rem;
  }
  
}

.fancy-btn {
  background: linear-gradient(90deg, rgb(216, 69, 40) 0%, rgba(176,106,179,1) 100%);
  color: white;
  width: 100%;
  border: none;
  font-family: var(--font_b) !important;
  /* border-radius: 0.8rem !important; */
  font-size: 0.8rem !important;
  cursor: pointer;
  margin: auto !important;
}
.fancy-btn:hover {
  background: linear-gradient(90deg, rgba(89,59,180,1) 0%, rgba(196,126,199,1) 100%);
}

@media screen and (max-width:768px) {
  .fancy-btn {
    font-size: 0.6rem !important;
    padding : 0.6rem 0.7rem !important;
  }
  
}
.fancy-btn.town{
  font-weight: 600;
  background: linear-gradient(90deg, rgb(55, 130, 210) 0%, rgba(0,123,255,1) 100%);
  color: white;
  
  
}


.fancy-btn.town:hover{
  background: linear-gradient(90deg, rgba(50,200,202,1) 0%, rgba(255,180,60,1) 100%);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.3);
  filter: brightness(110%);
}
.bottom-navbar{
  display: none;
}
/* Media query for screens with a width of less than 768 pixels */
@media screen and (max-width: 768px) {
  .bottom-navbar {
    display: flex; 
    position: fixed; /* Fixed position */
    left: 0; 
    right: 0; 
    bottom: 0; 
    justify-content: space-around; /* Space items evenly */
    align-items: center; /* Center items vertically */
    background-color: var(--bottom-navbar-bg);
    box-shadow: 0 -6px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    z-index: 2000; /* Ensure it's above other content */
    padding: 0.1rem 0; /* Some padding */
    border-radius:0.7rem 0.7rem 0 0 ; /* Rounded corners */
    height: 3.3rem; 
    box-sizing: border-box; /* Include padding and border in the height */
    padding-bottom: env(safe-area-inset-bottom); /* For iPhone X and newer */
  }
}


/* AI TOWN STYLES  ----------- */

.game-title {
  background: var(--orange-brand-accent);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 0.08em 0px #6e2146);
}


.game-frame {
  border-width: 36px;
  border-image-source: url(../../public/town-assets/ui/frame.svg);
  border-image-repeat: stretch;
  border-image-slice: 25%;
}
.game-progress-bar {
  border: 5px solid rgb(23, 20, 33);
}

@keyframes moveStripes {
  to {
    background-position: calc(100% + 28px) 0;
  }
}

.game-progress-bar-progress {
  background: repeating-linear-gradient(135deg, white, white 10px, #dfdfdf 10px, #dfdfdf 20px);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: moveStripes 0.5s linear infinite;
}


.info-frame{
  background: linear-gradient(rgba(41, 41, 41, 0.6), rgba(41, 41, 41, 0.6)),
  /* url(../assets/vdarkbg.png)center/cover no-repeat fixed; */
}

.shadow-solid {
  text-shadow: 0 0.1em 0 rgba(0, 0, 0, 0.5);
}
.game-info-section{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  width: 40%;
  height: 100%;
  max-height: 100vh;
  /* margin-left: 0.3rem; */
  
  overflow-y: auto;
}
.chat-messages-section{
  max-height: 79.2vh;
  overflow-y: auto;
  margin:0.4rem;
  border-radius: 1rem;
  background-color: rgba(59, 6, 99, 0.328);
}

.bubble {
  /* border-width: 30px; */
  /* border-image-source: url(../../public/town-assets/ui/bubble-left.svg); */

  /* border-image-repeat: stretch; */
  /* border-image-slice: 20%; */
  /* border-radius: 1rem; */
}

.bubble-mine {
  border-image-source: url(../../public/town-assets/ui/bubble-right.svg);
}

.box {
  border:2px solid var(--orange-brand-accent);
  border-radius: 1rem;
  margin: 1rem;
  /* border-image-source: url(../assets/ui/box.svg); */
  /* border-image-repeat: stretch;
  border-image-slice: 12.5%; */
}

.desc {
  /* border-width: 20px; */
  margin: 1rem;
  border-radius: 1rem;
  /* border-image-source: url(../assets/ui/desc.svg); */
  /* border-image-repeat: stretch; */
  /* border-image-slice: 28%; */
  /* border: 10px solid rgb(23, 20, 33); */
}

.town-chats {
  /* height: 30rem; */
  overflow-y: auto;
}

.login-prompt {
  border-width: 48px;
  border-image-source: url(../../public/town-assets/ui/jewel_box.svg);
  border-image-repeat: stretch;
  border-image-slice: 40%;
}

.button {
  border-width: 1em;
  border-image-source: url(../../public/town-assets/ui/button.svg);
  border-image-repeat: stretch;
  border-image-slice: 25%;
  cursor: pointer;
}

.button span {
  display: inline-block;
  transform: translateY(-15%);
}

@media (max-width: 640px) {
  .button {
    height: 40px;
    border-width: 0.75em;
    font-size: 16px;
  }

  .button > div,
  .button > span {
    vertical-align: top;
    line-height: 1;
  }
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  /* Inlining this image to avoid flashing during loading */
  border-image-source: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='2' width='14' height='13' fill='%23181425'/%3E%3Crect x='2' y='1' width='12' height='15' fill='%23181425'/%3E%3Crect y='3' width='16' height='11' fill='%23181425'/%3E%3Crect x='2' y='14' width='12' height='1' fill='%23262B44'/%3E%3Crect x='1' y='3' width='14' height='11' fill='%233A4466'/%3E%3Crect x='2' y='2' width='12' height='9' fill='%233A4466'/%3E%3Crect x='1' y='13' width='1' height='1' fill='%23262B44'/%3E%3Crect x='14' y='13' width='1' height='1' fill='%23262B44'/%3E%3C/svg%3E%0A");
}

.button:active span {
  transform: none;
}

p[contenteditable='true']:empty::before {
  content: attr(placeholder);
  color: #aaa;
}

.shape-top-left-corner {
  clip-path: polygon(0 0, 100% 0, 0 100%);
}



.rotated-image {

  transform: rotateX(28deg) rotateY(-25deg) rotateZ(20deg) scale(1.3);
  transform-origin: center center;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-container::before {
  content: '';
  position: absolute;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient( rgba(0,0,0,0.7), rgba(0,0,0,0.7));
  z-index: 20;
}

@media (max-width: 640px) {
  .image-container::before {
    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7));
  }
.rotated-image {
  transform: scale(1.6) rotateX(28deg) rotateY(-27deg) rotateZ(20deg);
}
}
