@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
/* Chat mode styles*/

  
  .typing {
    width: auto;
    height: 30px;
  }
  


/* Hide scrollbar for IE, Edge, and Firefox */
.chat-window {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

  .chat-header__chardp {
    width: 5rem;
    height: 5rem;
    border-radius: 20%;
    object-fit: cover;
    margin: 0 1rem;
  }

  .chat-message-chardp{
    margin: 0.1rem 0.3rem;
    width: 3rem;
    height: 3rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add shadow for contrast */
  }

     .character-name{
        color: var(--chat-container-text-color);
        font-family: var(--font_b);
    }
  
    @media screen and (max-width: 768px) {
      .character-name{
        width: 1rem;
        font-size: 1rem;
    }
    .character-creator-username{
      font-size: 0.5rem;
    }
    .chat-header__chardp{
      width: 4rem;
      height: 4rem;
      margin: 1rem 1rem;
     
    }
    .chat-message-chardp{
      width: 2.4rem;
      height: 2.4rem;

    }
  }
  
  .chat-message {
    padding: 0.7rem 0.5rem;
    /* margin: 0.5rem 0; */
    position: relative;
    font-weight: 900;
    display: flex;
  
  }
  
  .chat-message.user {
    align-self: flex-end;
    color: #edeff2;
    max-width: 50rem;
    border-radius:0.7rem;
    
  }

  
  .chat-message.char {
    align-self: flex-start;
    color: rgb(222, 235, 248);
    font-size: 1.3rem;
    min-width: 11rem;
    max-width: 50rem;
    border-radius:0.7rem;
  }

  @media screen and (max-width: 768px) {
    .chat-message.user {
      font-size: 1rem;
      max-width: 25rem;
    }

    .chat-message.char {
      font-size: 1rem;
      max-width: 25rem;
    }
    
  }
  
  .feedback-buttons {
    align-items: flex-end;
    background: rgba(66, 64, 63, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 1rem;
    padding: 1px 1rem ;
    display: flex;
    gap: 0.6rem;
    margin-left: auto;
    margin-top: 0.2rem;
  }
  
  .feedback-buttons button {
  
    transition: transform 0.1s;
  }
  
  /* .feedback-buttons button:active {
    transform: scale(1.3);
  } */
  
  .feedback-buttons button:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  
  .feedback-buttons button::after:active {
    background-color: #aaa;
  }
  .like-button:hover, .dislike-button:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  
  
  .like-button,
  .dislike-button {
    background: none;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    
    transition: background-color 0.3s;
    /* for smooth transition */
  }
  
  .like-button.active {
    background-color: #4CAF50;
    /* Green for liked */
  }
  
  .dislike-button.active {
    background-color: #f44336;
    /* Red for disliked */
  }
  
  .message-content {
    padding: 1rem;
    font-size: 1rem;
    font-weight:500;
    font-family: 'DM Sans', sans-serif;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    /* font-family: var(--font_b); */
  }

  .lighten-italic {
    filter: brightness(1.5);
    font-weight: 350;
    font-style: italic;
    display: block;
    
  }
  .darken-italic {
    filter: brightness(0.8);
    font-weight: 350;
    font-style: italic;
    display: block;
    
  }
  .edit-message-input {
    background: inherit; 
    border-radius: inherit; 
    border: none; 
    outline: none; 
    font-family: 'DM Sans';
    
    color: inherit; 
    padding: inherit;
    width: calc(100% - 1rem);
    box-sizing: border-box;
    resize: none; /* Disable manual resizing */
}

/* You may need to adjust for mobile responsiveness as well */
@media screen and (max-width: 768px) {
  .edit-message-input {
      font-size: 1rem; /* Match the mobile message text size */
      /* Other mobile specific adjustments */
  }
  .message-content{
    font-size: 0.8rem;
  
  }
}
  .chat-input {
    bottom: var(--input-bar-offset, 0px);
    margin: auto;
    display: flex;
    bottom: 0;
  width: 60%;
    padding: 1rem;
    position: relative;
    z-index: 2;

  }
  .chat-input::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(to top, rgba(6, 6, 6, 0.052),transparent );
    pointer-events: none; /* Prevents the fade effect from capturing mouse events */
  }
  
  
  .chat-input-area {
    flex: 1;
    border-radius: 2rem;
    background-color: rgb(245, 227, 227) !important;
    color: rgb(23, 23, 23) !important;
    font-size: 17px;
    margin : 0.2rem 0rem;
    padding:  0 20px;

    font-family: 'DM Sans', sans-serif;
    border: none;
    word-break: break-word  ;
  }
  .chat-input input:focus{
    outline: none;
  }
   
  .chat-input textarea {
    flex: 1;
    border-radius: 1rem;
    /* background-color: rgb(217, 216, 227); */
    color: rgb(23, 23, 23);
    width: auto;
    /* Adjust to accommodate flex property */
    font-size: 17px;
  }
  
  .chat-input button {
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    border-radius: 1rem;
    height: 3rem;
    cursor: pointer;
    font-weight: bold;
    transition: filter 0.3s; /* Transition for filter property */
  }
  
  .chat-input button:hover {
    filter: brightness(85%); /* Dim the button on hover */
  }
  
  @media screen and (max-width:768px) {
    .chat-input {
      width: 95%;
    }
    .chat-window{
      width: 100%;
    }
    .chat-header{
      width: 100%;
    }
    
  }
  .popup {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(186, 180, 180, 0.5);
    z-index: 100;
  }
  
  .popup button {
    background-color: #032c56;
    color: white;
    border: none;
    margin: 2% auto;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .popup input {
    width: 50%;
    border-radius: 5px;
    margin: 2% auto;
    padding: 10px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    /* font-size: 24px; */
    cursor: pointer;
    outline: none;
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    /* Make sure the backdrop is below the popup but above other content */
  }
  
  /* Other related chat UI styles will go here */
  
  