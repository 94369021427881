
@font-face {
  font-family: "Nunito";
  font-display: swap;
  src: url("./assets/fonts/Nunito-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Nunito";
  font-display: swap;
  src: url("./assets/fonts/Nunito-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  font-display: swap;
  src: url("./assets/fonts/Nunito-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Nunito";
  font-display: swap;
  src: url("./assets/fonts/Nunito-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito";
  font-display: swap;
  src: url("./assets/fonts/Nunito-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Silkscreen";
  font-display: swap;
  src: url("./assets/fonts/Silkscreen-Bold.ttf");
  font-weight: 700;

}
@font-face {
  font-family: "Silkscreen";
  font-display: swap;
  src: url("./assets/fonts/Silkscreen-Regular.ttf");
  font-weight: 400;

}
:root[data-theme="dark"] {
  --primary: #ffffff;
  --secondary: #f65b5b;
  --bg: #000;
  --muted: #444;
  --primary100: #ee4b4b1a;
  --black: #fff;
  --white: #000;
  --stroke: #515151;
}

:root {
  --font-nunito: "Nunito", sans-serif;
  --font-silkscreen: "Silkscreen", monospace;

  --primary: #ffffff;
  --primary100: rgba(237, 212, 173, 0.2);
  ;
  --primary200: rgba(228, 213, 206, 1);
  --primary400: rgba(213, 155, 91, 1);

  --primary700: #D59B5B;
  --text: rgba(30, 52, 69, 1);

  --secondary: rgba(255, 109, 0, 1);
  --bg: rgba(249, 249, 249, 1);
  --muted: #cbcbcb;
  --black: #000;
  --white: #fff;
  --stroke: rgba(30, 52, 69, 1);
}





/* 1. Use a more-intuitive box-sizing model */
*, *::before, *::after {
  box-sizing: border-box;
}

/* 2. Remove default margin */
* {
  margin: 0;
}

body {
  /* 3. Add accessible line-height */
  line-height: 1.5;
  /* 4. Improve text rendering */
  -webkit-font-smoothing: antialiased;
}

/* 5. Improve media defaults */
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

/* 6. Inherit fonts for form controls */
input, button, textarea, select {
  font: inherit;
}

/* 7. Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/* 8. Improve line wrapping */
p {
  text-wrap: pretty;
}
h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}
input:focus-visible , select:focus-visible{
  border: none;
  box-shadow: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrolbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrolbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

input::placeholder {
  color:var(--stroke);
  opacity: 1; /* Firefox */
}

input::-ms-input-placeholder { /* Edge 12 -18 */
  color:var(--stroke);

}

