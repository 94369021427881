/* Basic styles, you'll need to expand upon this */

@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque&family=Inclusive+Sans&family=Merriweather+Sans:ital,wght@1,500&family=Montserrat:wght@600&family=Open+Sans:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Transducer Regular';
  src: url('./fonts/transducer-font-family/TransducerTest-Regular-BF645c3dd843c3b.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Transducer Hairline';
  src: url('./fonts/transducer-font-family/TransducerTest-Hairline-BF645c3dd88a443.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Transducer Regular';
  src: url('./fonts/transducer-font-family/TransducerTest-RegularOblique-BF645c3dd87b9e4.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Transducer Extended';
  src: url('./fonts/transducer-font-family/TransducerTest-ExtendedBold-BF645c3dd848b2a.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Transducer Regular';
  src: url('./fonts/transducer-font-family/TransducerTest-Bold-BF645c3dd70a4f5.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

:root{
  --image: url('../public/lightforest.png');
  --image-mobile: url('../public/lightforest_mobile.png');
}

#root {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100svh;
  
}

html, body {
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}


body{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), var(--image) center/cover no-repeat fixed
 
}

@media screen and (max-width: 768px){
  
  .body{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), var(--image-mobile) center/cover no-repeat fixed
  
  }
  
}

.app-container {
  display: flex;
  height: 100%;
  width:100vw;
  
  /* overflow: hidden;  Prevent any overflow from the flex container itself */
}
.sidebar-container {
  width: 20vh; 
  z-index: 1000;
  
}

.content-container {
  flex-grow: 1; 
  overflow-y: auto; 
  width: 100vw;
  overflow-x: hidden;
  
  &::-webkit-scrollbar {
    width: 4px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::-webkit-scrollbar {
    opacity: 1;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--orange-brand-accent);
    
    border-radius: 10px;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--orange-brand-accent);
  }
}

