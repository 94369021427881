
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');

  
.login-button, .signup-button {
    background-color: var(--orange-brand-accent) !important;
    color: white;
    border: none;
    font-family: var(--font_b);
    border-radius: 0.4rem !important;
  font-size: 0.8rem !important;
    cursor: pointer;
    width: 100%;
    margin: 0.5rem !important;
}
.signup-button {
    background-color: rgb(58, 58, 141) !important;
}
@media screen and (max-width: 768px) {
    .login-button {
        font-size: 0.6rem !important;
    padding : 0.4rem 0.4rem !important;
    }
    
}
.logout-button {
    border-radius: 2rem !important;
    font-size: 1rem;
    width: 8rem !important;
    margin: 1rem 0 0 0!important;
    /* height: 3.5rem; */
    background-color: var(--logout-button-color) !important;
    color: var(--secondary-text-color) !important;
    cursor: pointer;

}
.username{
    font-family: 'Pixelify Sans', sans-serif;
    font-size: 1.2rem;
    padding: 2rem 1rem ;
    color: var(--primary-text-color);
    text-align: center;
    margin: auto;
}
.username-container {
    font-family: Arial, sans-serif;
    width: 80%;
    max-width: 500px;
    margin: 50px auto;
    background-color: #333;
    padding: 20px;
    color: #FFF;
    border-radius: 10px;
}

.username-container h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.username-container p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

.username-input[type="text"] { 
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    background-color: #FFF;
    color: #333;
}

.terms-container {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.terms-container a {
    color: #0099FF;
    margin-left: 5px;
}

.username-button {
    display: block;
    width: 100%;
    background-color: #0099FF;
    color: #FFF;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-weight: bold;
}

.username-button:hover {
    background-color: #0077CC;
}
