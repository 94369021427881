@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');



.selected-section{
    font-family: var(--font_b);
    /* background-color: var(--darker-bg); */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary-text-color);
}

.character-select-placeholders{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 1rem 0;
}
.selected-character-card{
  cursor: pointer;
  font-family: var(--font_b_extended);
  transition: transform 0.3s ease;
  padding:  0.4rem;
  margin:auto;
  width: 10rem;
  display: flex;
  flex-direction: column;
  height: 13rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background-color: var(--character-card-bg);
  color: var(--character-card-text);
  text-align: center;
  position: relative;
}
@media screen and (max-width:768px) {
  .selected-character-card{
  height: 12rem;
  width: 8rem;
  }
}
.selected-character-card img {
  width: 13rem;
  max-width: 100%;
  height: auto;
  border-radius: 0.3rem;
}

.selected-character-card h3 {
  flex: 1;
  margin: 0;
  font-family: var(--font_b_extended);
  font-size: medium;
  font-style: bold;
}
.selected-char-info{
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.6rem;
}

.selected-character-card:hover {
  /* filter: brightness(1.3); Increase brightness by 10% */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}
.plus-icon{
    
    color: var(--addnl-button-color);
    margin: 7rem 0.5rem 0 0;
    cursor: pointer;
}

.choose-character-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }
  .choose-character-card{
    cursor: pointer;
    font-family: var(--font_b_extended);
    transition: transform 0.3s ease;
    /* padding:  0.1rem; */
    /* margin-bottom:auto; */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
    width: 10rem;
    height: 12.5rem;
    background-color: var(--character-card-bg);
    color: var(--character-card-text);
    text-align: center;
  }

  .choose-character-card img{
    width: 10rem;
    height: 10rem;
    border-radius: 0.3rem;
  }
  .choose-char-info{
    margin-bottom: 0;
    margin-top: 0.2rem;
    font-size: 0.8rem;
  }

  .choose-character-card.selected {
    /* Styling for selected card */
    transform: scale(1.05);
    position: relative; 
  }
  
  .choose-character-card .subscript {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }

  
  .cast-container {
    align-items: center;
    margin: 0% auto;
    /* background-color: var(--cast-container-bg); */
    backdrop-filter: blur(2px);
    color: var(--cast-container-text);
    width: 100%;
    height: 100vh; 
  }
  
  .cast-header {
    display: flex;
    width: 100%;
    background: linear-gradient(to bottom, var(--cast-container-bg), var(--cast-window-bg));
    /* border-radius: 1rem; */
    /* box-shadow: 0px 0px 10px var(--character-card-bg); */
    
    align-items: center;
    padding: 0.5rem 1rem;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    margin-left: auto;
  }
  .dropdown button{
    border-radius: 0.1rem;
    /* height: 3rem; */
    font-size: 2rem;
    font-weight: bolder;
    color: var(--character-card-text);
    border: none;
    cursor: pointer;
    background: none;
  }
  .dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    border-radius: 0.2rem;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-content p {
    color: black;
    padding: 1rem 1rem;
    text-decoration: none;
    display: block;
  }
  .dropdown-content p:hover {
    background-color: #f1f1f1;
  }
 
  
  .cast-window {
    width: 100%;
    height: 80vh;
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    /* background: radial-gradient(circle at center, var(--header-background-color), var(--header-background-fade-to)); */
    /* background:var(--cast-window-bg); */
/* background-blend-mode: screen; */
    /* backdrop-filter: blur(2px); */
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .char{
    display: flex;
    margin: 0.3rem 0;
    font-family: var(--font_b);
    font-size: 0.8rem;
  }



  .botcast-char-img{
  width: 4rem;
  height: 4rem;
  border-radius: 20%;
  object-fit: cover;
  margin: 0  1rem;
  }
  .topic  {
    color: white;
    height: 3rem;
    font-family: var(--font_b);
  }

 @media screen and (max-width: 768px) {
  .char{
    font-size: 0.4rem;
  }
 
  .botcast-topic{
    font-size: 1rem;
  } 
}

  .cast-input {
    margin: auto;
    display: flex;
    width: 95%;
    height: 3rem;
    padding: 0.1rem 0.6rem;
    
    justify-content: space-between;

  }

  .botcast-topic {
    /* background-color: #27244b; */
    padding: 0.1rem;
    /* min-width: 35rem;   */
    margin:auto;
    font-size: 1.7rem;
    align-self: center;
    border-bottom: 1px solid var(--orange-brand-accent);

  }

  @media screen and (max-width: 768px) {
    .botcast-topic {
      min-width: 22rem;
    }
    
  }
  .stream-utils{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .cast-input input {
    flex: 1;
    /* padding: 10rem 15rem; */
    border-radius: 1rem !important;
    background-color: rgb(189, 189, 193);
    color: rgb(23, 23, 23);
    width: 50%;
    margin: auto;
    border: none;
    font-size: rem;
  }

  .cast-input input:focus {
    outline: none;
  }
  
  .start-btn {
    padding: 0.5rem 1rem !important;
    margin-left: 1rem !important;
    border-radius: 2rem !important;
    background-color: var(--orange-brand-accent) !important;
    color: var(--cast-container-text) !important;
    /* border: 2px solid var(--addnl-button-color) !important; */
    height: 2.4rem !important;
    font-family: var(--font_b)!important;
    cursor: pointer;
    font-weight: bold;
    transition: filter 0.3s; /* Transition for filter property */
  }
  
  .cast-input button {
    height: 2.6rem;
  }
  .cast-input button:hover {
    filter: brightness(85%); /* Dim the button on hover */
  }


  .cast-message {
    padding: 10px 10px;
    min-height: 35rem ;
    min-width: 35rem;
    margin: 0.2rem auto;
    position: relative;
    font-weight: bold;
    border-radius: 0.3rem;
    /* border: 1px solid var(--button-text-color); */
    font-size: 1.4rem;
    font-family: 'Open Sans', sans-serif;
    overflow-y: auto;
    display: flex; /* Use flexbox to center the content */

  }


   .cast-message-content {
    background-color: white;
    opacity: 0.9;
    font-family: 'Bangers', cursive;
    font-weight: 100;
    max-width: 30rem;
    color: black;
    border-radius: 5px;
    margin: 1rem 0.5rem 1rem 1rem ;
    padding: 20px;
    font-size: 1.2rem;
    gap: 2px;
    margin-top: auto;
    border: 2px solid #27244b;
   /* Additional styling for comic effect */
   box-shadow: 0px 0px 10px rgba(0,0,0,0.5); /* Shadow for depth */
}
@media screen and (max-width: 768px) {
  .cast-message {
    min-height: 22rem ;
    min-width: 22rem;
  }
  .cast-message-content{
      max-width: 15rem;
      font-size: 0.8rem;
  }
  
}
  .cast-message-header{
    display: flex;
  }
  .cast-message-content h4{
    margin: 0.2rem 0;
    font-style: italic;
    font-weight: lighter;
  }
  
  .cast-feedback-buttons button {
    transition: transform 0.1s;
  }

   .cast-feedback-buttons button:active {
    transform: scale(1.3);
  } 
  
  .cast-feedback-buttons button:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  

  
  .cancel-icon {
    color: rgb(132, 8, 8);
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; /* If you want it to show a pointer on hover */
  }

  .botcast-selector-header{
    color: var(--primary-text-color);
    font-family: var(--font_b);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
  }
  .stop-button{
    font-family: var(--font_b)!important;
    margin: auto!important;
  }