@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    overflow-y: auto;
    z-index: 3000;
    /* overflow: hidden; */
}
.popup-logo{
    width: 100px;
    background-image: url('../../../public/images/breakwall.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: zoom;
  border-radius: 40px;
}

.welcome-popup-content {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../../public/darkalt.png');
    padding: 1.2rem;
    /* box-sizing: border-box; */
    border-radius: 1rem;
    border: 1px solid var(--orange-brand-accent);
    display: flex;
    flex-direction: column;    
    font-family: var(--font_b);
    margin: auto;
    min-height: 400px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    color: var(--primary-text-color);
}

@media screen and (max-width: 768px) {
  
    .welcome-popup-content {
        width: 90%;
        /* max-height: 550px; */
        background-image: url('../../../public/vdarkbg.png');
        margin: 20% auto;
        
    }
}
    
.supwall-popup-content {
    background-color: var(--create-background-color);
    padding: 30px  30px;
    border-radius: 10px;
    width: 60%;
    font-family: var(--font-b_extended);
    max-width: 500px;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    color: var(--primary-text-color);
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    .supwall-popup-content {
        width: 90%;
    }
}

.popup-close-button{
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    color: var(--primary-text-color);
    background: none;
    cursor: pointer;
border: none;
    font-size: 1.5em;
}
.welcome-popup-content h1 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    font-family: var(--font-b_extended);
}

.info {
    /* background-color: var(--welcome-bg-secondary); */
    font-size: 0.9rem;
}

.info p {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.info p span {
    margin-right: 10px;
}

.accept-btn {
    background-color: var(--orange-brand-accent);
    color: white;
    font-family: var(--font_b);
    padding: 10px 15px;
    border: none;
    width: 40%;
    margin: auto;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.accept-btn:hover {
    filter: brightness(0.8)
}

.multi-chat-popup-img{
    width: 15em;
    margin: auto;
}

.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  padding: 0 1rem ;
    background-color: rgba(27, 9, 1, 0.859);
    z-index: 1000;
  }
  
  .search-close-btn {
    /* position: absolute; */
    margin: auto;

    color: var(--orange-brand-accent);
    cursor: pointer;
  }
  .search-area{
    display: flex;
    flex-direction: row;
    padding: 1rem ;

  }
  .main-search-input {
    padding: 10px ;
    border-radius: 2rem !important;
    padding: 0.7rem 2rem  !important;
    background-color: rgb(76, 75, 75) !important;
    color: white;
    border: none;
    font-size: 17px;
    max-width: 100%;
    font-family: var(--font_b);
  }
  .main-search-input:focus {
    outline: none;
  }
  
  .search-btn {
    margin: auto 2%!important;
    height: 2.4rem !important;
    border-radius: 0.7rem !important;
    background-color:var(--action-button-color) !important;

  }
  

  .search-custom-swiper-button-prev, .search-custom-swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(176, 184, 244, 0.5);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px; /* Adjust the size of the arrow symbol */
    color: black; /* Arrow color */
    z-index: 10; /* Ensure it's above other elements */
  }
  
  .search-custom-swiper-button-prev {
    left: 10px;
  }
  
  .search-custom-swiper-button-next {
    right: 10px;
  }
  
  .search-custom-swiper-button-prev:hover, .search-custom-swiper-button-next:hover {
   filter: brightness(65%); /* Dim the button on hover */
  }
  
  .search-results{
    /* padding-right: 1rem; */
    margin: auto;
    max-width: 90%;
  }
  
  .continue-username{
    background-color: var(--orange-brand-accent) !important;
    border-radius: 1rem !important;
  }