@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

  
.trending-character-card {
  cursor: pointer;
  color: white;
  transition: transform 0.3s ease;
  padding: 0.1rem;
  width: 9rem;
  height: 13.34rem;
  border-radius: 0.5rem;
  
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

@media (min-width: 768px) {
  .trending-character-card {
    width: 11rem;
    height: 16.34rem;
  }
}
.trending-character-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.5rem;
  background: linear-gradient(
    to bottom,
    rgba(0,0,0,0) 30%,
    rgba(0,0,0,0.6) 60%,
    rgba(0,0,0,0.9) 80%,
    rgb(4, 3, 2) 100%
  );
  z-index: 1;
}

.trending-character-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  z-index: 0;
}

.trending-character-card:hover {
  
  /* box-shadow: 0px 2px 2px var(--orange-brand-accent); */
  
}

@media screen and (max-width: 768px) {
  .trending-character-card:hover, .continue-character-card:hover {
    filter: brightness(1);
    border: none;
  }
  /* .trending-character-card p{
    font-size: 0.5rem;
    
  }
 */
}



  .character-popup {
    position: fixed; /* Make it overlay on the entire screen */
    top: 0;
    left: 0;
    width: 100vw; /* Cover the entire viewport width */
    height: 100vh; /* Cover the entire viewport height */
    background-color: rgba(0, 0, 0, 0.7); /* Black with 70% opacity to dim the background */
    display: flex; /* Use flexbox to center the content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* A high z-index so it stays on top of other content */
  }
  
  .popup-content {
    font-family: 'Pixelify Sans', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: var(--character-card-bg);
    background-color: var(--character-card-text);
    padding: 0.4rem;
    border-radius: 0.7rem; /* Rounded corners */
    /* max-width: 80%; Maximum width of 80% of the screen */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }
  
  /* Styling for the image to ensure it fits within the popup content */
  .popup-content img {
    width: 20rem;
    max-width: 100%;
    height: auto;
    border-radius: 0.7rem; /* Optional: Rounded corners for the image */
  }
  
  .popup-content button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    font-family: 'Pixelify Sans', sans-serif;
    color: var(--button-color);
    background-color: var(--button-text-color);
    border: none;
    border-radius: 0.7rem;
    cursor: pointer;
    transition: background-color 0.3s; 
  }

  .popup-content h2{
    font-family: 'Pixelify Sans', sans-serif;
    margin: 0.5rem 0.1rem;
  }
  
  .popup-content button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
  }
  

  /* TRANSITIONS */
/* Initial state */
.popup-enter {
    opacity: 0;
  }
  
  /* Transited to after component enters */
  .popup-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  
  /* State when component starts exiting */
  .popup-exit {
    opacity: 1;
  }
  
  /* Transited to after component exits */
  .popup-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }
  
  .profile-character-card {
    background-color: rgba(27, 9, 1, 0.6);
    color: var(--primary-text-color);
    font-family: var(--font_b);
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 12rem;
    margin: 0.4rem;
    
    
  }
  
  .profile-character-image {
    aspect-ratio: 1/1;
    max-height: 5rem;
    /* width: 5rem; */

    border-radius: 50%;
    
    
  }

 
  
  .profile-character-info p {
    background-color: var(--profile-bg-color);
    padding: 0.2rem 0.6rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    display: flex; /* Add this line */
    justify-content: space-between; /* Add this line */
    margin-bottom: 0.5rem; /* Adjust as needed for spacing between paragraphs */
    color: var(--button-text-color);
  }
  
  .profile-character-info p span {
    margin-left: auto; /* Add this line */
  }
  @media screen and (max-width: 768px) {
    .profile-character-image{
      height: 7rem;
    }
    .profile-character-info h3 {
      margin: 0;
      width: 9rem;
      text-align: left;
      font-size: 0.8rem;
    }
    
    .profile-character-info p {
      padding: 0.2rem 0.2rem;
      border-radius: 0.5rem;
      font-size: 0.6rem;
      display: flex; /* Add this line */
      justify-content: space-between; /* Add this line */
      margin-bottom: 0.5rem; /* Adjust as needed for spacing between paragraphs */
      color: var(--button-text-color);
    }

  }

  .private-denote{
    font-family: var(--font_b_extended);
    font-size: 0.8rem;
    color: var(--button-text-color) ;
    margin: 0.5rem 0;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.5s; /* You can adjust the duration */
    animation-fill-mode: both; /* Keeps the end state after the animation completes */
  }

  .fastFadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.5s; /* You can adjust the duration */
    animation-fill-mode: both; /* Keeps the end state after the animation completes */
  }