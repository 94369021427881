
/* :root{
  --scroll-height: 1700px;
} */
.continue-parent{
  display: flex;
    flex-direction: column;
    /* background-color: rgba(48, 48, 48, 0.449); */
    font-family: var(--font_b);
    color: var(--primary-text-color);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    margin-top: 6%;
    width: 20rem;
    bottom: 0;
    /* max-height: 100vh; */
    border-radius: 0 1rem 1rem 0rem;
    box-shadow: 1px 0 1px -3px rgba(0, 0, 0, 0.5);
}
.continue-container{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    width: 20rem;
    overflow-y: auto;
    height: 100vh;
    border-radius: 0 0rem 1rem 0rem;
    border-top: 1px solid var(--orange-brand-accent);
    border-bottom: 2px solid var(--orange-brand-accent);
    
z-index: 1000;
    box-shadow: 1px 0 1px -3px rgba(0, 0, 0, 0.5); 
}

.continue-container {
    scrollbar-color: var(--continue-area-bg);
    scrollbar-width: auto; /* 'auto' or 'thin' are also valid values */
  }
  
.continue-parent h1{
    font-family: 'Transducer Regular';
    font-size: 1.5rem;
    color: var(--primary-text-color);
    /* padding: 1rem; */
    text-align: center;
}

.continue-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px; 
  }
  
  .continue-container::-webkit-scrollbar-thumb {
    background-color:lighten( var(--blue-brand-accent), 20%);
    border-radius: 10px;
    border: 5px solid var(--orange-brand-accent);
  }
  
  
  .continue-container::-webkit-scrollbar {
    width: 2px; 
  }
  
  
  .continue-container::-webkit-scrollbar-thumb:active {
    background-color: var(--orange-brand-accent); 
  }
  

.tags-section {
    /* max-width: 90vw; */
    padding: 10px 0.3; 
  }

  @media (max-width: 768px) {
    .continue-parent{
      position: fixed;
      background-color: var(--continue-area-bg);
      top: 0;
      left: 0;
      right: 0;
      overflow-y: hidden;
      width: 100%;
      z-index: 999;

    }
    .continue-container {
        position: fixed;
        top: 5%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;

        padding-bottom:4rem;
     }

  
    .continue-container h1 {
      font-family: 'Transducer Regular';
      font-size: 1.5rem;
      color: var(--primary-text-color);
      text-align: center;
      padding: 1rem; /* Adjust padding as needed */
    }
    .continue-parent h1 {
      font-family: 'Transducer Regular';
      font-size: 1.5rem;
      color: var(--primary-text-color);
      text-align: center;
      padding: 1rem; /* Adjust padding as needed */
    }
  }


  .mySwiper .swiper-pagination {
    bottom: 10px; 
    background: rgba(0, 0, 0, 0.5); 
    border-radius: 1rem; 
    color: var(--primary-text-color) !important;
    padding:5px; 
    width: auto; 
    left: 50%; 
    margin-top: 1.4rem;
    transform: translateX(-50%);
    
  }
  .slide {
    background-size: cover;
    /* width: 99%; */
    width: 100%;
    height: 100%;
    font-family: var(--font_b);
    height: 25rem;
    border-radius: 1rem;
    cursor: pointer;
    
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(60, 15, 2, 0.258);
  }

  @media (max-width: 768px) {
    .slide {
      max-height: 11rem; 
      background-size: fit;
      /* width: 98%; */ 
    }
  }
  .slide:hover {
    /* transform: scale(0.95);
    transition: transform 0.3s ease-in-out; */
    /* border: 2px solid orange; */
    /* border-radius: 1rem; */
  }

  .town-ad-slide{
    background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), url('../../../public/carousel_assets/town.png') no-repeat center center;
    background-size:cover;
  }

  .chat-and-play-slide {
    background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), url('../../../public/carousel_assets/adventure.png') no-repeat center center;
    background-size:cover;
  }
  .create-botcast-slide {
    background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), url('../../../public/carousel_assets/experience.png') no-repeat center center;
    background-size: cover;
  }
  .create-character-slide {
    background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), url('../../../public/carousel_assets/create.png') no-repeat center center;
    background-size: cover;
  }
  .chat-characters-slide {
    background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), url('../../../public/carousel_assets/explore.png') no-repeat center center;
    background-size: cover;
  }
  .get-featured-slide {
    background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), url('../../../public/carousel_assets/featured.png') no-repeat center center;
    background-size: cover;
  }
  

  .sort-by-button{
    background-color: var(--continue-area-bg) !important;
    margin-left: 1rem !important;
    font-family: var(--font_b) !important;
    color: var(--primary-text-color) !important;
  }
.sort-menu-item{
    background-color: var(--tag-bg-color) !important;
    color: var(--primary-text-color) !important;
  }

  .load-more-button{
    background-color: var(--orange-brand-accent) !important;
    font-family: var(--font_b) !important;
    color: var(--primary-text-color) !important;
    padding: 0.5rem 1rem !important;
    display: block !important;
    margin: auto !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border-radius: 1rem !important;
  }

  .social-big-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 25rem;
    width: 100%;
    border-radius: 1rem;
    /* margin: 0.5rem; */
    font-family: var(--font_b);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    color: var(--primary-text-color);;
    
    cursor: pointer;
  }

  .discord-class{
    align-items: center;
    justify-content: center;
  
  }
  /* .social-big-container:hover{
    transform: scale(1.02);
  } */
  @media (max-width: 768px) {
    .social-big-container{
      height: 11rem;
    }
  }

  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes jump {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }