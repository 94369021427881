@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Pixelify+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
 
.profile-page {
    min-height: 100vh; 
    display: flex;
    flex-direction: column;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 3rem;
    font-family: var(--font_b);
    /* background-color: var(--profile-bg-color); */
  }
  
  .profile-header img {
    width: 10rem; /* Adjust as needed */
    height: 10rem; /* Adjust as needed */
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .profile-user-info {
    display: flex;
    flex-direction: column;
  }
  
  .profile-header-username {
    color: var(--primary-text-color);
    font-size: 2rem;
    font-weight: bold;
  }
  
  .full-name {
    font-size: 1.4rem;
    color: var(--primary-text-color);
  }
  
  .profile-subinfo {
    font-size: rem;
    color: var(--primary-text-color);
  }
  

  .tabs button {
    background-color: var(--tab-button-unselected);
    color: var(--primary-text-color);
    border: none;
    font-family: var(--font_b);
    border-radius: 1rem;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
  }
  
  .tabs button:not(:last-child) {
    margin-right: 0.3rem; /* Adjust space between tabs if necessary */
  }
  
  .tabs button:hover {
    filter: brightness(1.1);
  }
  
  .tab-content {
    padding: 1rem;
    flex: 1;
  }
  
  .tabs button.active-tab {
    color: var(--primary-text-color);
    background-color: var(--orange-brand-accent);
    position: relative; 
    
  }
  
    @media screen and (max-width:768px) {
    .profile-header {
      flex-direction: column;
      align-items: center;
    }
    .profile-header img {
      margin-right: 0;
      width: 8rem;
      height: 8rem;
      margin-bottom: 1rem;
    }
     .tabs button {
      font-size: 0.7rem;
  }
    
  }


  .character-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.6rem;
    padding: 1rem;
  }

  @media screen and (max-width: 768px) {
    .character-list {
      flex-direction: row;
      justify-content: center;
    }
    
  }
  .chat-card, .botcast-card {
    max-width: 21rem;
    height: auto;
    background-color: rgba(27, 9, 1, 0.6);
    color: var(--primary-text-color);
    font-family: var(--font_b);
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0.4rem;

  }
  .botcast-card{
    flex-direction: column;
  }

  
  .chat-card-info h3 {
    margin: 0;
    font-size: 0.9em;
    color: var(--button-text-color);
    text-align: left; /* Explicitly aligns text to the left */
  }
  
  .chat-card-info p {
    border-radius: 0.5rem;
    font-size: 1rem;
    display: flex; /* Add this line */
    justify-content: space-between; /* Add this line */
    text-align: left;
    margin-bottom: 0.5rem; /* Adjust as needed for spacing between paragraphs */
    color: var(--button-text-color);
  }

  .delete-popup .MuiPaper-root {
    background-color: var(--profile-bg-color);
    border-radius: 1rem;
    color: var(--primary-text-color) ;
    font-family: 'Pixelify Sans', sans-serif;
  }
.topic-header{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* padding: 0 1rem; */
  background-color: var(--topic-section-bg);
  color: whitesmoke;
}
.topic-header h2{
  /* margin-left: 45%; */
  /* margin-right:auto; */
  margin-left: auto;
  position: absolute;
  text-transform: capitalize;
  
}
@media screen and (max-width: 768px) {
  .topic-header h2{
    font-size: 0.8rem;
  }
  
}
.botcast-card-main{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

}
  .botcast-character-card{
    cursor: pointer;
    transition: transform 0.3s ease;
    /* border: 1px solid #ccc; */
    padding:  0.4rem;
    width: 11rem;
    margin: 0 4em;
    height: 14rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    background-color: var(--character-card-bg);
    color: var(--character-card-text);
    text-align: center;
}

.botcast-character-card img {
  width: 11rem;
  max-width: 100%;
  height: auto;
  border-radius: 0.3rem;
}

.botcast-character-card h3 {
  margin: 0 0.1rem;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .botcast-character-card{
      width: 8rem;
      height: 12rem;
      margin: 0;
  }
  .chat-card-info h3 {
    font-size: 1rem;
  }
   .chat-card-info p {
    font-size: 0.8rem;
  }
  /* .botcast-card-main{
    flex-direction: column;
  } */
}

.create-persona-container{
display: flex;
padding: 1rem;
flex-direction: column;
align-items: center;
color: var(--primary-text-color);
border-radius: 0.7rem;
background-color: var(--profile-card-bg);
font-family: var(--font_b);
cursor: pointer;
transition: transform 0.2s ease;
}
.create-persona-container:hover{
  filter: brightness(1.05);
  transform: scale(1.05);

}
.persona-form{
  background-color: var(--profile-bg-color) !important;
  font-family: var(--font_b) !important;
  color: var(--primary-text-color) !important;
  border-radius: 0.7rem !important;
}
.persona-form-label{
  color: var(--primary-text-color) !important;
 font-family: var(--font_b) !important;
}
.persona-form-input{
  color: var(--primary-text-color) !important;
  font-family: var(--font_b) !important;
}

.persona-card{
  width: 14rem;
  height: 6rem;
  margin: 0.4rem;
  background-color: var(--profile-card-bg);
  border-radius: 0.7rem;
  padding: 1rem;
  color: var(--primary-text-color);
}
.persona-card-utils{
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  gap: 2rem;
}
.persona-card-name-area{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: var(--font_b);
  font-weight: bold;
}

